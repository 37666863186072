import React, { useState, useEffect, useRef } from 'react';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import { Toast } from 'primereact/toast';
import Container from 'react-bootstrap/Container'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import Item from './items';
import { getMensajeError } from '../utils/callMensajeError';
import { GET_ELEMENTOS, UPDATE_ELEMENTO } from './catalogoslistService';


function CatalogoItems(props) {

  const toast = useRef(null);  
  const [first, setFirst] = useState(0);
  const [displayAdd,setDisplayAdd] = useState(false);
  const [numeroColumnas,setNumeroColumnas] = useState(0);
  const [renglon,setRenglon] = useState(null);  
  const [nombreCampoActivo,setNombreCampoActivo] = useState("");
  const [valorCampoActivo,setValorCampoActivo] = useState("0");
  const [valorCampoInactivo,setValorCampoInactivo] = useState("1");
  const [esAlta,setEsAlta] = useState(false);

  useEffect(() => {
    //console.log("props.catalogo: "+JSON.stringify(props.catalogo));
    setNumeroColumnas(props.catalogo.columnas.filter(item => item.visible===1).length);
    let campoActivo=props.catalogo.columnas.find(item => item.campoActivo);
    if (campoActivo){
      setNombreCampoActivo(campoActivo.nombre);
      let valor = campoActivo.campoActivo.split("|");
      setValorCampoActivo(valor[0]);
      setValorCampoInactivo(valor[1]);
    }
  });


  // Catalogo.
  const { loading, error, data,  refetch } = useQuery(GET_ELEMENTOS,
    {
      variables: { campos:{
          idAplicacion:parseInt(props.idAplicacion),
          idCatalogo: props.catalogo.idcatalogo,
          atributess: props.catalogo.columnas.map(item => {return item.nombre}),
          //wheress:[{nombre:"status",valor:"0"}]
          wheress:[]
        }
      },
      onError: (error) => {
        getMensajeError(error,"Catálogos No Cargados",toast);    
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    });
  
    const [updateElemento, { loading:loadingUpdate }] = useMutation(UPDATE_ELEMENTO, {
      onCompleted: data => {
        if (data.updateElement) {
          if(data.updateElement.resultado>0){
            toast.current.show({ severity: 'info', summary: 'Resultado', detail: data.updateElement.mensaje, life: 1000 });
           }else{
            toast.current.show({ severity: 'error', summary: 'Error', detail: data.updateElement.mensaje, sticky: true });
          }
        } else {
          toast.current.show({ severity: 'error', summary: 'Resultado', detail: "Hubo problemas al salvar el elemento", sticky: true });
        }
  
      },
      onError: (error) => {
        getMensajeError(error,"Error al salvar el elemento",toast);    
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      refetchQueries: [
        GET_ELEMENTOS,
        {
          variables:
          { campos:{
            idAplicacion:parseInt(props.idAplicacion),
            idCatalogo: props.catalogo.idcatalogo,
            atributess: props.catalogo.columnas.map(item => {return item.nombre}),
            //wheress:[{nombre:"status",valor:"0"}]
            wheress:[]
          }
        },
          fetchPolicy: "network-only"
        },
        'data'
      ]
  

    });

/*    useEffect(() => {
      console.log("entro ");
      if (data){
        console.log("data: "+JSON.stringify(data));
      }
  
    });
  
*/

  const listaItem = (valor) =>{
    let  result = valor.map((item) =>
      <li key={item.identificador}>
        {item.etiqueta}
      </li>
    );
    return <ul>{result}</ul>

  }

  const dynamicColumns = props.catalogo.columnas.map((col,i) => {
    /** idcolumna,    nombre,    descripcion,    tipo*/
    return <Column key={col.nombre}  style={(!col.visible || col.visible!==1)?{display:'none'}:{}}  body ={(data,props) => {
      //console.log("Col: "+JSON.stringify(data));
        let datosColumna=data.columnas.filter(item => item.nombre==col.nombre)[0];
        if(datosColumna.nombre===nombreCampoActivo){
          return datosColumna.valor===valorCampoActivo? "Activo":"Inactivo"
        }else if(col.tipo==="combo"){
          for (let i = 0; i < 10; i++) {
            //console.log("Busca: "+col.nombre+i);
            datosColumna=data.columnas.filter(item => item.nombre==col.nombre+i)
            if(datosColumna) {
              datosColumna=datosColumna[0];
              break;
            }
          }
          return datosColumna?datosColumna.valor:"";
        }else if(col.tipo==="multicombo"){
          return listaItem(datosColumna.multivalor)
        }else
          return datosColumna.valor
      }} 
      header={col.descripcion}></Column>
  });

  const headerDetalles = (
    <div className="table-header ">
      <div className="text-right"><Button style={{ width: "10%" }} type="button" label="+ Agregar" disabled={(loading ) ? 'disabled' : null} onClick={(e) => { editar(e,null); }} /> </div>      
    </div>
  );
  
  const footerDetalles = ((data && data.selectCatalogo.length > 0) ? 
  <ColumnGroup>
    <Row>
      <Column footer="Totales:" colSpan={numeroColumnas}/>
      <Column footer={data.selectCatalogo.length} />
    </Row>
  </ColumnGroup> : 
  <ColumnGroup>
    <Row>
      <Column footer="Totales:" colSpan={numeroColumnas}/>
      <Column footer={"Detalles: 0"} />
    </Row>
  </ColumnGroup>
  );
  
  const actionTemplate = (rowData) => {
    return (
        <React.Fragment>
            <a href="#" onClick={(e) => editar(e, rowData)}>Editar</a> 
            <span> | </span>
            <a href="#" onClick={(e) => eliminar(e, rowData)}>{rowData.columnas.find(item => item.nombre===nombreCampoActivo).valor===valorCampoActivo?"Desactivar":"Activar"}</a> 
        </React.Fragment>
    )
  }

  const editar = async (e, renglontmp) => {
    if (e)
      e.preventDefault();
    if (renglontmp){
      setEsAlta(false);
      setRenglon(renglontmp);
    }else{
      setEsAlta(true);
      setRenglon({columnas:props.catalogo.columnas.map(item => {return {nombre: item.nombre,valor:item.campoActivo?valorCampoActivo:""}})});
    }  
    setDisplayAdd(true);
/*    try {
      const { data } = await enviarEmailsFunction({
        variables: {
          "identificador": campania['identificador'],
          "id_usuario": idUsuario,
          "status": nextStatus
        }
      });
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al enviar el correo', life: 3000 });
    }*/

  }

  const ocultaEdicion = () => {
    setDisplayAdd(false);
    refetch();
  }


  const eliminar = async (e, renglon) => {
    if (e)
      e.preventDefault();

      confirmDialog({
        message: 'Se eliminará el elemento',
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => {

          let nombrePrimaryKey=props.catalogo.columnas.find(item => item.primaryKey===1).nombre;
          let valorPrimaryKey=renglon.columnas.find(item => item.nombre===nombrePrimaryKey).valor;
          //console.log("nombreCampoActivo: "+nombreCampoActivo);
          let valorActivo=renglon.columnas.find(item => item.nombre===nombreCampoActivo).valor===valorCampoActivo?valorCampoInactivo:valorCampoActivo;

          if (nombrePrimaryKey && valorPrimaryKey){
            await updateElemento({
              variables: {
                campos:{
                  idAplicacion:parseInt(props.idAplicacion),
                  idCatalogo: props.catalogo.idcatalogo,
                  setts: [{"nombre": nombreCampoActivo,"valor": valorActivo}],
                  wheress:[{"nombre": nombrePrimaryKey,"valor": valorPrimaryKey}]
                }
              }
            })
          }else{
            toast.current.show({ severity: 'error', summary: 'Falat de elemento', detail: 'No se encontró el primary key del catálogo', sticky: true });
          }
        }
      });
  }

  return (
      <Container >
        <Toast ref={toast} />
        <DataTable value={data?data.selectCatalogo:[]} header={headerDetalles} footerColumnGroup={footerDetalles} className="p-datatable-sm" 
          emptyMessage="No existen opciones registrada" paginator rows={10}  
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} regitros"
          responsiveLayout="scroll">
          {dynamicColumns}
          <Column header="Acci&oacute;n" body={actionTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
        </DataTable>


        <Dialog header={esAlta?"Alta":"Modificación"} 
        visible={displayAdd}
        style={{ width: '40vw' }}
        modal
        className="p-fluid" onHide={ocultaEdicion}>

          <Item item={renglon} catalogo={props.catalogo} esAlta={esAlta} idAplicacion={props.idAplicacion} ocultaEdicion={ocultaEdicion}></Item>

        </Dialog>



      </Container>

      
  );
}

export default CatalogoItems;