import React, { useState, useEffect } from 'react';
import CatalogosList from './components/catalogoslist';
import { Container, Row } from 'react-bootstrap';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css'
import "primeflex/primeflex.css"


import './App.css';


const App = (props) => {

  const [Loading, Usuario] = useState(false);

  //let history = useHistory();

  useEffect(() => {

  })


  return (
    <div >
      <Container className="App-header">
        { props.id_app ?
          <CatalogosList id_app={props.id_app}></CatalogosList> :
          "No se ha mandado el Id de la apliciación..."
        }
      </Container>

    </div>
  );
}

export default App;

