import { gql } from '@apollo/client';

export const GET_CATALOGOS = gql`
query($idAplicacion: Int!){
  aplicacionesbyId(idAplicacion: $idAplicacion) {
    idaplicacion
    nombre
    catalogos {
      idcatalogo
      nombre
      descripcion
      idcatalogapl
      columnas {
        idcolumna
        nombre
        descripcion
        tipo
        visible
        primaryKey
        tamanio
        catalogoFuente
        campoActivo
        c_ide_label
      }
    }
  }
}`;

export const GET_ELEMENTOS = gql`
query($campos: CamposSelectInput!){
  selectCatalogo(campos: $campos){
    columnas {
      nombre
      valor
      multivalor{
        identificador
        etiqueta
      }
    }
  }
}
`;

export const GET_COMBO = gql`
query($idCatalogo: Int!, $idColumna: Int!, $identificador: String!, $etiqueta: String!){
  selectCombo(idCatalogo: $idCatalogo, idColumna: $idColumna, identificador: $identificador, etiqueta: $etiqueta){
    idcolumna
    items{
      etiqueta
      identificador
    }
  }
}
`;

export const UPDATE_ELEMENTO = gql`
mutation updateElement(
  $campos: CamposUpdateInput!
) {
  updateElement(
    campos: $campos
  ) {
    resultado
    mensaje
  }
}
`;

export const INSERT_ELEMENTO = gql`
mutation insertElement(
  $campos: CamposInsertInput!
) {
  insertElement(
    campos: $campos
  ) {
    resultado
    mensaje
  }
}
`;