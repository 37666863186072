
export const getMensajeError = (error,mensaje,toast) => {
    if (error.graphQLErrors && Array.isArray(error.graphQLErrors) && error.graphQLErrors.lenght > 0)
    toast.current.show({ severity: 'info', summary: mensaje, detail: error.graphQLErrors[0].message, sticky: true });
  else if (error.networkError)
    toast.current.show({ severity: 'info', summary: mensaje, detail: 'Error de red', sticky: true });
  else
    toast.current.show({ severity: 'info', summary: mensaje, detail: error.message, sticky: true });

}

