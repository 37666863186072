import React, { useState, useEffect, useRef } from 'react';
import { useApolloClient, useQuery, useMutation,useLazyQuery } from '@apollo/client';
import { Toast } from 'primereact/toast';
import Container from 'react-bootstrap/Container'
import { Button } from 'primereact/button';
import { useForm, Controller,useFieldArray } from 'react-hook-form';
import { confirmDialog } from 'primereact/confirmdialog';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import * as moment from 'moment';
import { getMensajeError } from '../utils/callMensajeError';
import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { UPDATE_ELEMENTO, INSERT_ELEMENTO, GET_COMBO } from './catalogoslistService';



function Item(props) {

  const toast = useRef(null);  
  const [valorCampoActivo,setValorCampoActivo] = useState("0");
  const [valorCampoInactivo,setValorCampoInactivo] = useState("1");
  const [combos,setCombos] = useState([]);

  const { control, formState: { errors }, setValue, getValues, handleSubmit, reset } = useForm({ mode: 'onSubmit' },{ campos: props.catalogo.columnas.filter(item => item.visible===1).map(item2 =>{
    return {
      idcolumna: item2.idcolumna,
      nombre: item2.nombre,
      descripcion: item2.descripcion,
      tipo: item2.tipo,
      campoActivo: item2.campoActivo,
      tamanio: item2.tamanio,
      valor: props.item.columnas.find(col => col.nombre===item2.nombre).valor
    }
  }) });  
  const { fields } = useFieldArray({
    control,
    name: "campos"
  });

  const [updateElemento, { loading:loadingUpdate }] = useMutation(UPDATE_ELEMENTO, {
    onCompleted: data => {
      if (data.updateElement) {
        if(data.updateElement.resultado>0){
          toast.current.show({ severity: 'info', summary: 'Resultado', detail: data.updateElement.mensaje, life: 1000 });
          setTimeout(() => {props.ocultaEdicion();}, 1000);
         }else{
          toast.current.show({ severity: 'error', summary: 'Error', detail: data.updateElement.mensaje, sticky: true });
        }
      } else {
        toast.current.show({ severity: 'error', summary: 'Resultado', detail: "Hubo problemas al salvar el elemento", sticky: true });
      }

    },
    onError: (error) => {
      getMensajeError(error,"Error al salvar el elemento",toast);    
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const [insertElemento, { loading:loadingInsert }] = useMutation(INSERT_ELEMENTO, {
    onCompleted: data => {
      if (data.insertElement) {
        if(data.insertElement.resultado>0){
          toast.current.show({ severity: 'info', summary: 'Resultado', detail: "Se realiza el alta", life: 1000 });
          setTimeout(() => {props.ocultaEdicion();}, 1000);
         }else{
          toast.current.show({ severity: 'error', summary: 'Error', detail: data.insertElement.mensaje, sticky: true });
        }
      } else {
        toast.current.show({ severity: 'error', summary: 'Resultado', detail: "Hubo problemas al salvar el elemento", sticky: true });
      }

    },
    onError: (error) => {
      getMensajeError(error,"Error al salvar el elemento",toast);    
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const client = useApolloClient();


  useEffect(() => {
    //console.log("props: "+JSON.stringify(props));

    let campos=props.catalogo.columnas.filter(item => item.visible===1).map(item2 =>{
      let valor = props.item.columnas.find(col => col.nombre===item2.nombre).valor;
      if (item2.tipo==='date'){
        valor= moment(valor, moment.ISO_8601).toDate();
      }else if (item2.tipo==='combo'){
          valor= valor?parseInt(valor):null;
      }else if (item2.tipo==='multicombo'){
            valor= props.item.columnas.find(col => col.nombre===item2.nombre).multivalor;
      }
      return {
        idcolumna: item2.idcolumna,
        nombre: item2.nombre,
        descripcion: item2.descripcion,
        tipo: item2.campoActivo?"campoActivo":item2.tipo,
        campoActivo: item2.campoActivo,
        tamanio: item2.tamanio,
        valor: valor
      }
    });


    let combos_=props.catalogo.columnas.filter(item => item.tipo==="combo" || item.tipo==="multicombo");
    if (combos_){
        let combostmp = combos_.map(async (element) =>{
          let identEtie=element.c_ide_label.split("|");

          let itemtt= await client.query({
            query: GET_COMBO,
            fetchPolicy: "network-only",
            nextFetchPolicy: "network-only",
            variables: { idCatalogo: element.catalogoFuente, idColumna: element.idcolumna, identificador: identEtie[0], etiqueta: identEtie[1]},
          }).then(resp => {
            let item={idcolumna: resp.data.selectCombo.idcolumna,combo: resp.data.selectCombo.items};
            setCombos(oldCombos => [...oldCombos,item] );            
            return item;
          })
          .catch((error) => {
            getMensajeError(error,"Catálogos No Cargados",toast);    
            return null;
          });   
          return itemtt;
        });

    }
    reset({
      campos:campos
    });
    let campoActivo=props.catalogo.columnas.find(item => item.campoActivo);
    if (campoActivo){
      let valor = campoActivo.campoActivo.split("|");
      setValorCampoActivo(valor[0]);
      setValorCampoInactivo(valor[1]);
    }


  }, [props.catalogo]);

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Claro'
  });


  const campaniaDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="buttonCancelar"
        />

        <Button
          label="Guardar"
          icon="pi pi-check"
          className="buttonGuardar"
            /> 
    </React.Fragment>
  );  

  const onSubmit = (data) => {
    confirmDialog({
      message: 'Se salvará el elemento',
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      acceptLabel:"Si",
      rejectLabel:"No",
      acceptClassName: 'p-button-danger',
      accept: async () => {
        if (props.esAlta){
          let setts = data.campos.map(item =>{
            if (item.tipo=='multicombo')
              return {nombre:item.nombre,valor:item.valor.map(ident =>{return ident.identificador}).join(',')};
            else
              return {nombre:item.nombre,valor:item.valor.toString()};
          });
          await insertElemento({
            variables: {
              campos:{
                idAplicacion:parseInt(props.idAplicacion),
                idCatalogo: props.catalogo.idcatalogo,
                setts: setts
              }
            }
          });
        }else{
          let setts = data.campos.map(item =>{
            if (item.tipo=='multicombo')
              return {nombre:item.nombre,valor:item.valor.map(ident =>{return ident.identificador}).join(',')};
            else
              return {nombre:item.nombre,valor:item.valor.toString()}
          });
          let nombrePrimaryKey=props.catalogo.columnas.find(item => item.primaryKey===1).nombre;
          let valorPrimaryKey=props.item.columnas.find(item => item.nombre===nombrePrimaryKey).valor;
          //console.log("Sets: "+JSON.stringify(setts));
          await updateElemento({
            variables: {
              campos:{
                idAplicacion:parseInt(props.idAplicacion),
                idCatalogo: props.catalogo.idcatalogo,
                setts: setts,
                wheress:[{"nombre": nombrePrimaryKey,"valor": valorPrimaryKey}]
              }
            }
          });
        }

      }
    });
  };


  const getCombobyIdCol = (idcolumna) =>{
    let combosSe=combos.find(item => item.idcolumna===idcolumna);
    if (combosSe)
      return combosSe.combo;
    else
    return [];
  }

  const getFormErrorMessage = (name) => {
    //console.log("Errores: "+JSON.stringify(errors));
    return errors.campos && errors.campos[name] && <small className="p-error">{errors.campos[name].valor.message}</small>
  };

  const getElemntoTag = (item,index) => {
    if (item.tipo==='varchar'){
      return <Controller  name={`campos.${index}.valor`} control={control} rules={{ required: 'El campo es requerido.' }} render={({ field, fieldState }) => (
                <InputText {...field} maxLength={item.tamanio}></InputText>
              )} />
    }else if (item.tipo==='int'){
      return <Controller  name={`campos.${index}.valor`} control={control} rules={{ required: 'El campo es requerido.' }} render={({ field, fieldState }) => (
        <InputText {...field} maxLength="10"></InputText>
      )} />

    }else if (item.tipo==='date'){
      return <Controller  name={`campos.${index}.valor`} control={control} rules={{ required: 'El campo es requerido.' }} render={({ field, fieldState }) => (
        <Calendar {...field} dateFormat="dd/mm/yy" locale="es" {...field}  showIcon />
      )} />

    }else if (item.tipo==='campoActivo'){
      return <Controller  name={`campos.${index}.valor`} control={control} rules={{ required: 'El campo es requerido.' }} render={({ field, fieldState }) => (
        <Dropdown {...field} optionValue="identificador" options={[{identificador: valorCampoActivo,descripcion: "Activo"},{identificador: valorCampoInactivo,descripcion: "Inactivo"}]} optionLabel="descripcion" />
      )} />


    }else if (item.tipo==='combo'){
      return <Controller name={`campos.${index}.valor`} control={control} rules={{ required: 'El combo es requerido.'}} render={({ field, fieldState }) => (
        <Dropdown {...field} optionValue="identificador" options={getCombobyIdCol(item.idcolumna)} optionLabel="etiqueta" />
      )} />

    }else if (item.tipo==='multicombo'){
      return <Controller name={`campos.${index}.valor`} control={control} rules={{ required: 'El combo es requerido.'}} render={({ field, fieldState }) => (
        <MultiSelect {...field} options={getCombobyIdCol(item.idcolumna)} optionLabel="etiqueta" placeholder="Selecciona una opción"  />
      )} />
    }else return ""
  };

  return (
    <Container >
      <form id="hook-form" onSubmit={handleSubmit(onSubmit)} >
        <Toast ref={toast} />
        <div className="card">
            {fields.map((item, index) => (
              <div key={item.nombre} className="grid">
                <div className="col-4">
                    <label htmlFor={`campos.${index}.valor`} className={classNames({ 'p-error': errors.name })}>{item.descripcion}</label>
                </div>
                <div className="col-8">
                  {getElemntoTag(item, index)}
                  <br />
                  {getFormErrorMessage(`${index}`)}
                </div>
              </div>
            ))}
        </div>
        <br></br>
        <input type="submit" value='Guardar' /> 
      </form>
    </Container>
  );
}

export default Item;