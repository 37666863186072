import React, { useState, useEffect, useRef } from 'react';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import { Toast } from 'primereact/toast';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Dropdown } from 'primereact/dropdown';
import { GET_CATALOGOS } from './catalogoslistService';
import  CatalogoItems  from './catalogoItems';
import { getMensajeError } from '../utils/callMensajeError';


function CatalogosList(props) {

  const toast = useRef(null);  
  const [idCatalogo, setIdCatalogo] = useState(null);
  const [catalogo_, setCatalogo_] = useState([]);


  // Catalogos.
  const { loading, error, data } = useQuery(GET_CATALOGOS,
    {
      variables: { idAplicacion: parseInt(props.id_app) },
      onError: (error) => {
        getMensajeError(error,"Catálogos No Cargados",toast);    
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only"
    });
  
  const cambiaCatalogo = (e) => {
    setIdCatalogo(e.value);
    setCatalogo_(data.aplicacionesbyId.catalogos.find(item => item.idcatalogo===e.value));
  }
  
  return (
    <div >
      <Toast ref={toast} />

      <div >
        <Container >
          <Row>
            <Col>
              <Container >
                <Dropdown
                  id="comboCatalogos"
                  optionValue="idcatalogo"
                  value={idCatalogo}
                  optionLabel="nombre"
                  onChange={cambiaCatalogo} disabled={(loading || error) ? 'disabled' : null }
                  options={data ? data.aplicacionesbyId.catalogos : []}
                  placeholder="Seleccione el catálogo" />
                </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              {
                idCatalogo ?
                    <CatalogoItems idAplicacion={props.id_app} catalogo={catalogo_} ></CatalogoItems>
                : ""
              }
            </Col>
          </Row>



        </Container>
      </div>
    </div>

  );
}

export default CatalogosList;