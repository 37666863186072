import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import reportWebVitals from './reportWebVitals';
import { createUploadLink } from 'apollo-upload-client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Agent } from 'https';




const creaApolloClient = async (host) =>{
  let uri = await  fetch(host+"/env-config.js") // llama al servidor del contendor???
        .then((res) =>{ return res.text(); })
        .then((data) => {
          //console.log("URI: "+data.slice(0,data.lastIndexOf(","))+"}");
          return JSON.parse(data.slice(0,data.lastIndexOf(","))+"}");
        });  
  //console.log("URI: "+JSON.stringify(uri));
  return new ApolloClient({
    cache: new InMemoryCache({ addTypename: false }),
    link: createUploadLink({
      uri: uri.REACT_APP_API_ENDPOINT_SERVER,// "https://dgti-ees-catalogos-back-end-staging.k8s.funcionpublica.gob.mx/graphql",
      headers: { 'Access-Control-Allow-Origin': '*' },
      fetchOptions: {
        agent: new Agent({ rejectUnauthorized: false })
      }
    }),
  });
}

/*
const client = creaApolloClient();*/
/*
const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: createUploadLink({
    uri: "https://dgti-ees-catalogos-back-end-staging.k8s.funcionpublica.gob.mx/graphql", 
    headers: { 'Access-Control-Allow-Origin': '*' }
  }),
});*/

window.renderCatalogos = async (containerId,host, history) => {
  let idAplicacion =document.getElementById(containerId).getAttribute("id_app");
  let host_ =document.getElementById(containerId).getAttribute("host");
  //console.log("host: "+host_);
  const client = await creaApolloClient(host_);
  ReactDOM.render(
    <ApolloProvider client={client}>
        <App history={history} id_app={idAplicacion}/>
    </ApolloProvider>,
    document.getElementById(containerId)
  );
};

window.unmountCatalogos = containerId => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
 };
 
 if (!document.getElementById('Catalogos-container')) {
  ReactDOM.render(<App />, document.getElementById('root'));
 }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();